body {
    padding: 0;
    margin: 0;
    font-family: calibri, helvetica, arial;
	background-color: rgb(50,50,50);
    color: rgb(250,250,250);
}
input, textarea {
    padding: 5px;
    border-radius: 5px;
    font-family: calibri, helvetica, arial;
}
a {
    color: yellow;
}

/* navbar styling */

#navbar {
    position: fixed;
    height: 100px;
    width: 100%;
    margin: 0;
    padding: 0;
}
#nav {
    margin: 0px;
    height: 60px;
    background-color: rgb(20,20,20);
	color: rgb(230,230,230);
    border-bottom: 1px solid rgb(80,80,80);
}
#searchBar {
    height: 40px;
    border-top: 1px solid rgb(80,80,80);
    background-color: rgb(30,30,30);
}
#searchElem {
    width: 60%;
    margin: 10px 20%;
}
#searchBar input {
    box-sizing: border-box;
    height: 20px;
    width: 90%;
    vertical-align: top;
    background-color: rgb(60,60,60);
    border: 1px solid rgb(80,80,80);
    border-radius: 10px 0px 0px 10px;
    padding: 0px 0px 0px 10px;
    color: rgb(200,200,200);
}
#searchBar button {
    width: 10%;
    height: 20px;
    color: rgb(150,150,150);
    border: 0px;
    border-radius: 0px 10px 10px 0px;
    background-color: rgb(50,50,50);
    cursor: pointer;
}
#navbar li {
    text-decoration: none;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    transition: 0.2s;
}
#navbar .navItem {
    width: 7%;
}
#navbar li:hover {
    background-color: rgb(30,30,30);
}
.navborder {
    border-left: 1px solid rgb(150,150,150);
    border-right: 1px solid rgb(150,150,150);
}
#navbar #logo {
    font-size: 2em;
    height: 100%;
    float: left;
    padding-left: 20px;
	cursor: pointer;
}
.signedin, .signedout {
    text-align: center;
    height: 60px;
    width: 15%;
    float: right;
}
#sample_urls li {
	margin: 30px 0;
}

/* footer */

#footer {
    border-top: 1px solid rgb(100,100,100);
    margin: 20px 10% 0px 10%;
    padding: 20px 0;
    width: 80%;
}
#footer .item {
    width: 25%;
    text-align: center;
    display: inline-block;
}
#footer a {
    color: rgb(200,200,200);
    text-decoration: none;
    line-height: 300%;
}
#footer a:hover {
    text-decoration: underline;
    color: rgb(250,250,250);
}

/* generic form styling */

#content {
    padding-top: 100px;
}
.generic-form {
    width: 70%;
    margin: 0 15%;
    padding: 20px 0;
    text-align: center;
}
.section-header {
    padding-top: 20px;
    margin-top: 30px;
    border-top: 1px solid rgba(150,150,150,0.3);
}
.form-button {
    margin: 15px 0;
}
.form-header {
    margin: 10px 0;
}
.form-small {
    margin: 0 0 20px 0;
}
.field {
    display: block;
    margin: 10px 10%;
    text-align: center;
}
.dist {
    margin: 30px 10%;
}
.field label {
    margin: 0 20px 0 0;
    width: 20%;
}
.field input, .field textarea {
    width: 40%;
}
.field textarea {
    vertical-align: middle;
}
.field textarea {
    margin-top: 20px;
}
.submit-input input {
    width: 30%;
    margin: 20px 35%;
    padding: 5px 0px;
    cursor: pointer;
}
.notes {
    text-align: center;
    margin: 20px 0;
}
.section-append {
    border-top: 1px solid rgba(50,50,50,0.3);
    padding-top: 30px;
    margin-top: 20px;
}
.collection-div {
	width: 60%;
	margin: 10px 20%;
	padding: 10px 0;
	font-size: 1.2em;
	border: 1px solid rgb(200,200,200);
	border-radius: 10px;
	background-color: rgb(235,235,235);
	transition: 200ms;
	cursor: pointer;
}
.collection-div:hover {
	background-color: rgb(250,250,250);
}
.queryItem {
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(80,80,80);
    margin-left: 5px;
}

#security-questions {
	border: 1px solid rgb(200,200,200);
	border-radius: 15px;
	padding: 40px 20px;
    margin: 50px auto 30px auto;
	background-color: rgb(90,90,90);
}
.pendingElem {
    height: 800px;
}
.addUrl {
    background-color: rgb(90,90,90);
    margin: 25px 20% 25px 20%;
    border-radius: 15px;
    padding: 5px;
    width: 60%;
}

/* collections styles */

.collectionElem {
    padding: 20px;
    line-height: 150%;
    background-color: rgb(60,60,60);
    border: 1px solid rgb(150,150,150);
    box-shadow: 0px 0px 3px rgb(200,200,200);
    cursor: pointer;
    transition: 0.2s;
}
.collectionElem:hover {
    border: 1px solid rgb(220,220,220);
    background-color: rgb(65,65,65);
}
.collectionAttribute {
    padding: 5px 15px;
    background-color: rgb(40,40,40);
    border-radius: 5px;
    border-left: 5px solid rgb(100,100,100);
    margin: 0px 10px;
}
.text-with-box {
    color: rgb(200,200,200);
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid rgb(80,80,80);
    border-left: 5px solid rgb(80,80,80);
    background-color: rgb(45,45,45);
    text-decoration: none;
    font-weight: lighter;
}
.text-with-box-plain {
    color: rgb(200,200,200);
    padding: 8px 20px;
    border-radius: 15px;
    border: 1px solid rgb(80,80,80);
    background-color: rgb(35,35,35);
}
.text-with-box-hover:hover {
    color: rgb(250,250,250);
    text-decoration: underline;
}

/* url styles */

.url {
    margin-top: 35px;
    text-align: left;
}
.urlName {
    padding: 5px 20px;
    border: 1px solid rgb(200,200,200);
    border-left: 2px solid rgb(200,200,200);
    border-right: 2px solid rgb(200,200,200);
    border-radius: 5px;
    background-color: rgb(35,35,35);
    font-size: 1.1em;
}
.urlDescription {
    padding: 10px;
    border: 1px solid rgb(100,100,100);
    border-left: 5px solid rgb(250,250,250);
    border-radius: 5px 5px 0px 0px;
    background-color: rgb(45,45,45);
}
.urlHyper {
    padding: 8px 20px;
    font-size: 0.9em; 
    border: 1px solid rgb(100,100,100);
    border-left: 5px solid rgb(250,250,250);
    background-color: rgb(45,45,45);
    border-radius: 0px 0px 5px 5px;
}
.urlDetail {
    border-radius: 5px;
    border: 1px solid rgb(80,80,80);
    box-shadow: 0px 0px 2px rgb(150,150,150);
}

/* table styles */

.view-table th, .update-table th {
    background-color: rgb(20,20,20);
}
.view-table, .update-table {
    margin: 20px 0 0 0;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border: 1px solid rgb(150,150,150);
    background-color: rgb(60,60,60);
}
.view-table tr, .update-table tr {
    border-bottom: 1px solid rgb(150,150,150);
}

.view-table td, .view-table th {
    padding: 5px 0;
    border: 1px solid rgb(180,180,180);
}
.update-table td, .update-table th {
    width: 33%;
    padding: 8px 10px;
}
.update-table input, .update-table textarea {
    width: 90%;
    margin: 0% 5%;
    background-color: rgb(200,200,200);
}
.update-table .short {
    width: 5%;
}

.two-col td, th {
    width: 50%;
}
.three-col td, th {
    width: 33%;
}
.table-view-row {
    padding: 20px;
    line-height: 150%;
    border: 1px solid rgb(150,150,150);
    box-shadow: 0px 0px 3px rgb(200,200,200);
}
.table-view-cell {
    padding: 5px 15px;
    background-color: rgb(40,40,40);
    border-left: 5px solid rgb(100,100,100);
    margin: 0px 10px;
}

/* widget styles */

#widgets {
    width: 80%;
    margin: 30px 10%;
}
.widget {
    width: 49%;
    margin: 15px 1% 15px 0px;
    padding: 15px 20px;
    display: inline-block;
    vertical-align: top;
    background-color: rgb(80,80,80);
    box-sizing: border-box;
    border-radius: 15px;
}
.widget2 {
    width: 99%;
}
.widget table {
    width: 100%;
    margin-top: 15px;
}
.widget table, .widget td {
    border: 1px solid rgb(150,150,150);
    border-collapse: collapse;
    padding: 5px 10px;
}
.widget-header {
    background-color: rgb(50,50,50);
}
.widget tbody td {
    background-color: rgb(60,60,60);
}
.widgetWithSrc {
    cursor: pointer;
    transition: 0.5s;
}
.widgetWithSrc:hover {
    color: yellow;
    text-decoration: underline;
}
.widget span {
    padding: 5px 15px;
    background-color: rgb(50,50,50);
    border: 1px solid rgb(100,100,100);
    border-radius: 10px;
    margin-left: 5px;
    font-size: 1.1em;
}
#intro {
    padding: 15px;
    line-height: 150%;
    border: 1px solid rgb(100,100,100);
    border-radius: 15px;
    background-color: rgb(40,40,40);
    margin-bottom: 20px;
}
.introDash {
    margin: 0px 0px 10px 20px;
}
.widget .contributorItem {
    margin: 8px 5%;
    padding: 10px 20px;
    border: 1px solid rgb(200,200,200);
    border-left: 8px solid rgb(200,200,200);
    border-radius: 8px;
    background-color: rgb(50,50,50);
    text-align: center;
}

.widgetWithSrc .user-col, .widgetWithSrc .updated-col {
    background-color: rgb(45,45,45);
    border-left: 2px solid rgb(100,100,100);
    text-align: center;
}

/* static page styles */

.staticPage {
    width: 80%;
    margin: 50px 10% 50px 10%;
}
.staticPage li {
    margin: 20px 0px;
}
.faqQuestion {
    padding: 5px 15px;
    background-color: rgb(20,20,20);
    border-radius: 5px;
    margin-top: 30px;
}
.faqAnswer {
    padding: 5px 0px 10px 20px;
    border-left: 5px solid rgb(200,200,200);
    border-bottom: 1px solid rgb(200,200,200);
}
.teamProfile {
    width: 25%;
    padding: 15px;
    text-align: center;
    border: 1px solid rgb(150,150,150);
    background-color: rgb(20,20,20);
    border-radius: 15px;
}

/* devmode */

#devMode {
    width: 80%;
    margin: 50px 10% 0px 10%;
    text-align: center;
}
#devMode textarea {
    width: 80%;
    margin: 20px 10%;
}
#devMessageElem {
    margin: 50px 0px 20px 0px;
    padding: 20px;
    border: 1px solid rgb(150,150,150);
    border-radius: 15px;
    box-shadow: 0px 0px 2px rgb(100,100,100);
    background-color: rgb(80,80,80);
}
#devMessageNotice {
    text-align: left;
}
#devMessages {
    text-align: left;
    width: 90%;
    margin: 0 5%;
}
#devMessages p {
    margin: 20px 0px;
    padding: 10px;
    background-color: rgb(100,100,100);
    border-radius: 5px;
    border: 1px solid rgb(150,150,150);
    border-left: 8px solid rgb(150,150,150);
}

/* generic styles */

.important-text {
    color: yellow;
    background-color: rgb(45,45,45);
    padding: 3px 5px;
}
.yellow {
    color: yellow;
}
.left-space {
    margin-left: 30px;
}
.left-space-mini {
    margin-left: 15px;
}
.left-content {
    text-align: left;
}
.left-border {
    border-left: 5px solid rgb(100,100,100);
    padding-left: 15px;
}
.float-right {
    float: right;
}
.error {
    color: orangered;
}
.lineHeight200 {
    line-height: 200%;
}
.lineHeight250 {
    line-height: 250%;
}
.no-wrap {
    white-space: nowrap;
}
.viewElem {
    margin: 20px 0px;
}
.some-padding {
    padding: 5px 20px;
}
.side-padding {
    padding: 5px 20px;
}
.vert-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}
.some-margin-vertical {
    margin-top: 30px;
    margin-bottom: 30px;
}
.margin-vertical {
    margin-top: 35px;
    margin-bottom: 35px;
}
.some-margin-top {
    margin-top: 30px;
}
.mini-margin-top {
    margin-top: 5px;
}
.data-margin-bottom {
    margin-bottom: 15px;
}
.slight-margin-bottom {
    margin-bottom: 15px;
}
.mini-margin-bottom {
    margin-bottom: 5px;
}
.width30 {
    width: 30%;
}
.width50 {
    width: 50%;
}
.no-margin {
    margin: 0px;
}
.no-margin-bottom {
    margin-bottom: 0px;
}
.border-top-mini {
    padding-top: 30px;
    border-top: 1px solid rgb(100,100,100);
}
.inline {
    display: inline-block;
}
.width-full {
    width: 100%;
}
.left-button {
    padding: 5px 25px;
    border-radius: 8px 0px 0px 8px;
}
.right-button {
    padding: 5px 25px;
    border-radius: 0px 8px 8px 0px;
}
.url-format {
    word-break: break-word;
}
.toggle-on {
    background-color: rgb(40,40,40);
    color: rgb(250,250,250);
    cursor: default;
}
.toggle-off {
    background-color: rgb(200,200,200);
    color: rgb(0,0,0);
    cursor: pointer;
}
.toggle-on, .toggle-off {
    border: 1px solid rgb(250,250,250);
}
